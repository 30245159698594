import { ValuesOf } from "@services/type-utils";

export const TRIGGER_HAPPENED = "happened";
export const TRIGGER_NOT_HAPPENED = "not_happened";

export const SEVERITY_LOW = "LOW";
export const SEVERITY_MEDIUM = "MEDIUM";
export const SEVERITY_HIGH = "HIGH";

export const EVENT = "event";
export const TURNAROUND_PARAM = "turnaround_param";

export const POBT = "pobt";
export const SOBT = "sobt";
export const EOBT = "eobt";
export const SIBT = "sibt";
export const EIBT = "eibt";
export const LTD = "ltd";
export const ALDT = "aldt";
export const AIRCRAFT_START = "aircraft_start_ts";
export const AIRCRAFT_END = "aircraft_end_ts";

export const SHORT = "SHORT";
export const LONG = "LONG";

export const SET = "SET";
export const EMPTY = "EMPTY";
export const UNIFIED = "unified";
export const SAFETY_EVENT = "safety-event";
export const SAFETY_ALERT = "safety-alert";
export const PUSHBACK_SPEED_EVENT = "pushback-speed-safety-event";
export const CATERING_HANDRAILS_EVENT = "catering-handrail-safety-event";
export const BELT_GUARDRAIL_EVENT = "belt-guardrail-safety-event";
export const WEIGHTED_EVENT = "weighted-safety-event";
export const PUSHBACK_ANGLE_EVENT = "pushback-angle-safety-event";

export type IncidentDTO = {
  config: any; // TODO: IncidentConfig
  custom_text?: string | null;
  data: any; // TODO: IncidentData
  detecting: boolean;
  id: string;
  inbound_company_iata: string | null;
  inbound_flight_number: string | null;
  original_stand_id: string | null;
  outbound_company_iata: string | null;
  outbound_flight_number: string | null;
  retracted: boolean;
  stand_id: string;
  ts: number;
  turnaround_id: string;
};

export type IncidentSeverity =
  | typeof SEVERITY_HIGH
  | typeof SEVERITY_MEDIUM
  | typeof SEVERITY_LOW;

export type TurnEndings =
  | typeof POBT
  | typeof SOBT
  | typeof LTD
  | typeof EOBT
  | typeof EIBT
  | typeof SIBT
  | typeof ALDT
  | typeof AIRCRAFT_END;

export type TurnMilestones = TurnEndings | typeof AIRCRAFT_START;

export type TurnaroundEventAttribute = {
  eventName: string;
  objectName: string;
  type: typeof EVENT;
};

export type TurnaroundParameterAttribute = {
  type: typeof TURNAROUND_PARAM;
  parameterName: TurnMilestones;
};

export type TurnaroundAttribute =
  | TurnaroundEventAttribute
  | TurnaroundParameterAttribute;

export type TurnaroundPointInTime = {
  timeShift: number;
  turnaroundAttribute: TurnaroundAttribute;
};
type Trigger = typeof TRIGGER_HAPPENED | typeof TRIGGER_NOT_HAPPENED;
type SearchInterval = {
  startEvent: TurnaroundAttribute;
  startTimeShift?: number;
  endEvent: TurnaroundAttribute;
  endTimeShift?: number;
};
type UnifiedIncidentConfigData = {
  incidentType: typeof UNIFIED;
  type: typeof UNIFIED;
  detectedAttributes: TurnaroundAttribute[];
  endRangeBoundary: TurnaroundPointInTime | null;
  firingPointInTime: TurnaroundPointInTime | null;
  startRangeBoundary: TurnaroundPointInTime;
  trigger: Trigger;
};
type SafetyEventConfigData = {
  intervalToSearchIn?: SearchInterval;
  inverseOperation?: boolean;
  minRangeDuration?: number;
  opNameToSearchFor?: string;
  trigger: Trigger;
  type: typeof SAFETY_EVENT;
  incidentType: typeof SAFETY_EVENT;
};
type SafetyAlertIncidentData = {
  safetyEventConfigId?: string;
  type: typeof SAFETY_ALERT;
  incidentType: typeof SAFETY_ALERT;
};
type CateringHandrailConfigData = {
  greenOperations?: string[];
  redOperations?: string[];
  type: typeof CATERING_HANDRAILS_EVENT;
  incidentType: typeof SAFETY_EVENT;
};
type PushbackSpeedConfigData = {
  pushbackSpeedLimit: number;
  type: typeof PUSHBACK_SPEED_EVENT;
  incidentType: typeof SAFETY_EVENT;
};
type GuardrailConfigData = {
  type: typeof BELT_GUARDRAIL_EVENT;
  incidentType: typeof SAFETY_EVENT;
};
type WeightedConfigData = {
  weightThreshold: number;
  type: typeof WEIGHTED_EVENT;
  incidentType: typeof SAFETY_EVENT;
};
type PushbackAngleConfigData = {
  pushbackAngleLimit: number;
  type: typeof PUSHBACK_ANGLE_EVENT;
  incidentType: typeof SAFETY_EVENT;
};

type BaseIncidentConfig = {
  id: string;
  active: boolean;
  customText: string | null;
  excludedAircraftTypes: string[];
  excludedStands: string[];
  group: string;
  inboundFlightStatus: typeof SET | typeof EMPTY | null;
  outboundFlightStatus: typeof SET | typeof EMPTY | null;
  requiredAircraftTypes: string[];
  requiredStands: string[];
  severity: IncidentSeverity;
  requiredTurnaroundLength: typeof SHORT | typeof LONG;
  incidentType: typeof UNIFIED | typeof SAFETY_EVENT | typeof SAFETY_ALERT;
};

export type UnifiedIncidentConfig = BaseIncidentConfig & {
  data: UnifiedIncidentConfigData;
};

export type SafetyAlertIncidentConfig = BaseIncidentConfig & {
  data: SafetyAlertIncidentData;
};

export const SAFETY_TYPES = {
  PUSHBACK_SPEED: "pushback_speed",
  CHOCKS: "chocks",
  WINGWALKER: "wingwalker",
  DEFAULT: "default",
  WEIGHTED: "weighted",
  CATERING_HANDRAIL: "catering_handrail",
  SAFETY_VESTS: "safety-vests",
  BRAKE_CHECK: "brake-check",
  RAMP_AGENT: "ramp_agent",
  TOWBAR_ANGLE: "towbar_angle",
  LEFT_CATERING_HANDRAIL_NOT_DEPLOYED: "left_catering_handrail_not_deployed",
  RIGHT_CATERING_HANDRAIL_NOT_DEPLOYED: "right_catering_handrail_not_deployed",
  FOD_WALK: "fod-walk",
  SAFETY_STOP: "safety-stop",
  PROVO_RAILS: "provo-rails",
} as const;

export type SafetyType = ValuesOf<typeof SAFETY_TYPES>;

export type SafetyEventIncidentConfigData =
  | SafetyEventConfigData
  | PushbackSpeedConfigData
  | CateringHandrailConfigData
  | GuardrailConfigData
  | WeightedConfigData
  | PushbackAngleConfigData;

export type SafetyEventIncidentConfig = BaseIncidentConfig & {
  safetyType: SafetyType;
  data: SafetyEventIncidentConfigData;
};

export type IncidentConfig =
  | UnifiedIncidentConfig
  | SafetyEventIncidentConfig
  | SafetyAlertIncidentConfig;
