import flatten from "lodash/flatten";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";
import { chain } from "lodash";

import { captureException } from "@sentry/browser";

import { di, getConfig } from "@di";
import { ApronAlert } from "@models/apronAlert";
import { CameraOutage } from "@models/cameraOutage";
import { Detection, DetectionPartial } from "@models/detection";
import { IncidentConfig } from "@models/incidentConfig";
import { Pts } from "@models/pts";
import { Replay, Turnaround } from "@models/turnaround";

import { IS_DEV, TURNAROUND_MIN_DURATION } from "@constants";
import { camelCaseKeys, convertObjUtcToMilliseconds } from "@services/data";
import { getNow, toMilliSeconds, toMilliSecondsOrNull } from "@services/time";
import { isFiniteNumber } from "@services/utils";
import { ConfigStore } from "@stores/ConfigStore";
import { TimelineResponse, TurnaroundsResponse } from "../types";
import { AssaiaUser } from "@frontend/assaia-ui";

export const parseTurnarounds = (
  data: any[],
  fixPOBTOutOfBounds: boolean,
  turnaroundsStartTimestamp: ConfigStore["turnaroundsStartTimestamp"],
  turnaroundsVisibleRangeSizeByRole: ConfigStore["turnaroundsVisibleRangeSizeByRole"],
  user: AssaiaUser
): TurnaroundsResponse => {
  let minTurnEnd = 0;
  if (turnaroundsVisibleRangeSizeByRole) {
    (user.profile.roles || []).forEach((role) => {
      let restriction = turnaroundsVisibleRangeSizeByRole[role.toLowerCase()];
      if (restriction) {
        restriction = Date.now() - toMilliSeconds(restriction);
        minTurnEnd = Math.max(minTurnEnd, restriction);
      }
    }, []);
  }
  if (minTurnEnd) {
    data = data.filter((item) => {
      const turnEnd = toMilliSecondsOrNull(item.end_ts);
      return !turnEnd || turnEnd >= minTurnEnd;
    });
  }

  if (turnaroundsStartTimestamp) {
    data = data.filter((item) => {
      const standId = item.stand_id;
      const start = toMilliSecondsOrNull(item.start_ts) as number;
      const minStart = isFiniteNumber(turnaroundsStartTimestamp)
        ? turnaroundsStartTimestamp
        : turnaroundsStartTimestamp[standId] || 0;

      return start >= toMilliSeconds(minStart);
    });
  }

  const turnarounds = chain(data)
    .map((d) => parseTurnaround(d, fixPOBTOutOfBounds))
    .compact()
    .orderBy(["start"], ["desc"])
    .value();
  const fetchedCount = data.length;

  return {
    turnarounds,
    fetchedCount,
  };
};

//TODO use type from apiSpec
export const parseTurnaround = (
  data: any,
  fixPOBTOutOfBounds: boolean
): Turnaround | null => {
  // FIXME: Use actual types from the API, not dummy ones like this type bellow
  type RawReplay = {
    url: string;
    speed: number;
    start_ts: number;
    end_ts: number;
  };

  const replays = Object.entries<RawReplay>(data.replays || {}).reduce<
    Record<string, Replay>
  >((acc, [key, rawReplay]) => {
    acc[key] = {
      url: rawReplay.url,
      speed: rawReplay.speed,
      startTs: toMilliSeconds(rawReplay.start_ts),
      endTs: toMilliSeconds(rawReplay.end_ts || data.end_ts),
    };

    return acc;
  }, {});

  data.params = data.params || {};

  const res: Turnaround = {
    id: data.id,
    start: toMilliSeconds(data.start_ts),
    end: toMilliSecondsOrNull(data.end_ts),
    pushbackMaxSpeed: data.pushback_speed_max,
    replays,
    authorized: data.authorized !== false,
    inboundFlight: data.inbound_flight
      ? camelCaseKeys(data.inbound_flight)
      : null,
    outboundFlight: data.outbound_flight
      ? camelCaseKeys(data.outbound_flight)
      : null,
    progress: data.progress || null,
    standId: data.stand_id,
    originalStandId: data.original_stand_id,
    dedicatedAirline: data.dedicated_airline || null,
    state: data.state || null,
    aircraftType: null,
    params: {
      aircraft_end_ts: toMilliSecondsOrNull(data.params.aircraft_end_ts),
      aircraft_on_stand_end_ts: toMilliSecondsOrNull(
        data.params.aircraft_on_stand_end_ts
      ),
      aircraft_on_stand_start_ts: toMilliSecondsOrNull(
        data.params.aircraft_on_stand_start_ts
      ),
      aircraft_start_ts: toMilliSecondsOrNull(data.params.aircraft_start_ts),
      ardt: toMilliSecondsOrNull(data.params.aircraft_ready_ts),
      flight_ardt: toMilliSecondsOrNull(data.params.flight_ardt),
      prdt: toMilliSecondsOrNull(data.params.predicted_aircraft_ready_ts),
      aldt: toMilliSecondsOrNull(data.params.aldt),
      eibt: toMilliSecondsOrNull(data.params.eibt),
      eobt: toMilliSecondsOrNull(data.params.eobt),
      ltd: toMilliSecondsOrNull(data.params.ltd),
      pobt: toMilliSecondsOrNull(data.params.pobt),
      sibt: toMilliSecondsOrNull(data.params.sibt),
      sobt: toMilliSecondsOrNull(data.params.sobt),
      tobt: toMilliSecondsOrNull(data.params.tobt),
      initial_eobt: toMilliSecondsOrNull(data.params.initial_eobt),
      aobt: null,
      aibt: null,
      tsat: toMilliSecondsOrNull(data.params.tsat),
      asat: toMilliSecondsOrNull(data.params.asat),
      asrt: toMilliSecondsOrNull(data.params.asrt),
      aircraft_or_pushback_ts: toMilliSecondsOrNull(
        data.params.aircraft_or_pushback_ts
      ),
      aircraft_ready_ts: toMilliSecondsOrNull(data.params.aircraft_ready_ts),
      predicted_aircraft_ready_ts: toMilliSecondsOrNull(
        data.params.predicted_aircraft_ready_ts
      ),
      apbg: toMilliSecondsOrNull(data.params.apbg),
      ctot: toMilliSecondsOrNull(data.params.ctot),
    },
  };

  //tmp hack for empty flight info
  if (res.inboundFlight && !Object.keys(res.inboundFlight).length) {
    res.inboundFlight = null;
  }
  if (res.outboundFlight && !Object.keys(res.outboundFlight).length) {
    res.outboundFlight = null;
  }

  if (res.inboundFlight) {
    res.inboundFlight.scheduledInBlockTime =
      res.inboundFlight.scheduledDateTime;
    res.inboundFlight = convertObjUtcToMilliseconds(res.inboundFlight);
    //TODO remove hack, aibt not included in params from api
    res.params.aibt = res.inboundFlight.actualInBlockTime || null;
  }
  if (res.outboundFlight) {
    res.outboundFlight.scheduledOffBlockTime =
      res.outboundFlight.scheduledDateTime;
    res.outboundFlight = convertObjUtcToMilliseconds(res.outboundFlight);

    // cast to boolean in case of `isStarFlight` (`data.outbound_flight.is_star_flight`) is unexpected nullish value
    res.outboundFlight.isStarFlight = Boolean(res.outboundFlight.isStarFlight);

    //TODO remove hack, aobt not included in params from api
    res.params.aobt = res.outboundFlight.actualOffBlockTime || null;
  }

  res.aircraftType =
    res.inboundFlight?.aircraftType || res.outboundFlight?.aircraftType || null;

  if (res.end) {
    res.progress = 1;
  }

  if (fixPOBTOutOfBounds) {
    if (res.end) {
      if (res.params.pobt !== null && res.outboundFlight?.actualOffBlockTime) {
        res.params.pobt = res.outboundFlight.actualOffBlockTime;
      }
    } else if (res.params.pobt && res.params.pobt < getNow()) {
      res.params.pobt = getNow();
    }
  }

  if (res.end && res.end - res.start <= TURNAROUND_MIN_DURATION) {
    const error = new Error(
      `[API#parseTurnaround] Got invalid turnaround "${res.id}": duration is less than ${TURNAROUND_MIN_DURATION}.`
    );

    captureException(error);

    if (IS_DEV || di.resolve("configOverrides").isDebug) {
      console.error(error);
    }

    return null;
  }

  return res;
};

export function parseDetections(
  data: any[],
  standId: string,
  parseItem: (d: any) => DetectionPartial | Detection
) {
  let detections = data.map(parseItem);
  if (window.ignoredOperations) {
    detections = detections.filter(
      (d: DetectionPartial) => !window.ignoredOperations.includes(d.type)
    );
  }

  const {
    minOperationConfidence,
    ignoreMinOperationConfidenceOps,
    mergableOperations: mergeOperationsFlag,
    turnaroundsStartTimestamp,
  } = getConfig();
  if (minOperationConfidence && ignoreMinOperationConfidenceOps) {
    detections = detections.filter(
      (d: DetectionPartial) =>
        ignoreMinOperationConfidenceOps.includes(d.type) ||
        (d.confidence && d.confidence >= minOperationConfidence)
    );
  }
  if (mergeOperationsFlag) {
    detections = mergeOperations(detections);
  }

  const ts =
    typeof turnaroundsStartTimestamp === "object" && turnaroundsStartTimestamp
      ? turnaroundsStartTimestamp[standId]
      : turnaroundsStartTimestamp;
  if (ts) {
    detections = detections.filter((d) => d.start >= toMilliSeconds(ts));
  }

  return sortBy(detections, "start");
}

export function parsePartialDetection(data: any): DetectionPartial {
  const { detectionTypesMap } = getConfig();
  const detectionType = detectionTypesMap[data.op_name];
  if (!detectionType) {
    const error = new Error(
      "[API#parsePartialDetection] Missing meta for " + data.op_name
    );

    captureException(error);

    if (IS_DEV || di.resolve("configOverrides").isDebug) {
      console.error(error);
    }
  }

  const detection: DetectionPartial = {
    id: data.id,
    confidence: data.confidence,
    start: toMilliSeconds(data.start_ts),
    end: toMilliSecondsOrNull(data.end_ts),
    startType: data.start_type,
    endType: data.end_type,
    startLabel: detectionType?.startEventLabel || { "en-GB": data.start_type },
    endLabel: detectionType?.endEventLabel || { "en-GB": data.end_type },
    endState: data.end_state,
    type: data.op_name,
    label: detectionType?.label || { "en-GB": data.op_name },
    origin: "api",
  };
  if (detection.endState === "RETRACTED") {
    detection.end = null;
  }

  if (di.resolve("configOverrides").isDebug) {
    detection._rawData = JSON.stringify(data, null, 2);
  }

  return detection;
}

export function parseDetection(data: any): Detection {
  const detection: Detection = {
    ...parsePartialDetection(data),
    startConfidence: data.start_confidence,
    endConfidence: data.end_confidence,
    startDetectionGap: toMilliSecondsOrNull(data.start_detection_gap),
    endDetectionGap: toMilliSecondsOrNull(data.end_detection_gap),
    startState: data.start_state,
    endState: data.end_state,
    bboxRanges: {},
  };
  detection.bboxRanges = parseBBoxes(
    data.bbox_ranges,
    detection.start,
    detection.end
  );

  return detection;
}

export function parseCameraOutage(data: any): CameraOutage {
  return {
    id: data.id,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    camera: data.camera_id,
  };
}

function parseBBoxes(
  data: any,
  detectionStart = 0,
  detectionEnd: number | null = null
): Detection["bboxRanges"] {
  const result: Detection["bboxRanges"] = {};

  if (!data) {
    return result;
  }

  Object.entries(data).forEach(([camera, ranges]: any) => {
    result[camera] = ranges.map((r: any) => {
      const startTs = r.start_ts
        ? Math.max(toMilliSeconds(r.start_ts), detectionStart)
        : detectionStart;
      let endTs: number | null = null;
      if (!detectionEnd) {
        endTs = toMilliSecondsOrNull(r.end_ts);
      } else {
        if (!r.end_ts) {
          endTs = detectionEnd;
        } else {
          endTs = Math.min(toMilliSeconds(r.end_ts), detectionEnd);
        }
      }

      return {
        startTs,
        endTs,
        box: r.box,
        hires: r.hires.map((h: any) => ({
          url: h.url,
          ts: toMilliSecondsOrNull(h.ts),
        })),
        id: r.bbox_id,
      };
    });
  });

  const allRanges = Object.values(result).flat();

  if (!allRanges.some((r) => r.startTs === detectionStart)) {
    const box = minBy(allRanges, "startTs");
    if (box) {
      box.startTs = detectionStart;
    }
  }

  if (detectionEnd && !allRanges.some((r) => r.endTs === detectionEnd)) {
    const box = maxBy(allRanges, "endTs") || allRanges.find((r) => !r.endTs);
    if (box) {
      box.endTs = detectionEnd;
    }
  }

  return result;
}

export function mergeOperations(detections: DetectionPartial[]) {
  const { mergableOperations } = getConfig();
  const mergeableKeys = flatten(Object.values(mergableOperations));
  const newOperations = [
    ...detections.filter((o) => !mergeableKeys.includes(o.type)),
  ];

  for (const key in mergableOperations) {
    let detectionsToMerge: DetectionPartial[] = detections.filter(
      ({ type }) => mergableOperations[key]?.includes(type)
    );
    if (!detectionsToMerge.length) {
      continue;
    }

    detectionsToMerge = sortBy(detectionsToMerge, (d) => d.start);

    let detection;
    while ((detection = detectionsToMerge.shift())) {
      const range: [number, number] = [
        detection.start,
        detection.end || getNow(),
      ];
      const newDetection = { ...detection };

      for (const detection2 of [...detectionsToMerge]) {
        const range2: [number, number] = [
          detection2.start,
          detection2.end || getNow(),
        ];
        if (!(range[0] <= range2[1] && range[1] >= range2[0])) {
          break;
        }

        if (range2[1] > range[1]) {
          newDetection.id = `${detection.id}-${detection2.id}`;
          newDetection.end = detection2.end;
          newDetection.endType = detection2.endType;
          newDetection.endLabel = detection2.endLabel;
        }
        detectionsToMerge = detectionsToMerge.filter(
          (o) => o.id !== detection2.id
        );
      }
      newOperations.push(newDetection);
    }
  }
  return newOperations;
}

export function parseAlert(data: any): ApronAlert {
  const alert: ApronAlert = camelCaseKeys(data);
  alert.ts *= 1000;
  alert.fake = false;
  alert.data = camelCaseKeys(data.data);

  alert.data.incidentType = data.config.incident_type;
  // RE made hack, so we must fix incident_type here
  if (data.config.incident_type === "weighted-safety-event") {
    alert.data.incidentType = "safety-event";
  }

  alert.data.type = data.data.strategy;

  alert.config = parseIncidentConfig(data.config);

  if (alert.inboundFlightNumber && alert.inboundCompanyIata) {
    alert.inboundFlightNumber =
      alert.inboundFlightNumber + alert.inboundCompanyIata;
  }
  if (alert.outboundFlightNumber && alert.outboundCompanyIata) {
    alert.outboundFlightNumber =
      alert.outboundCompanyIata + alert.outboundFlightNumber;
  }

  if (alert.data.incidentType === "safety-event") {
    const strategy = data.data.strategy;
    const newStrategies = [
      "multi-config-safety-event",
      "catering-handrail-safety-event",
      "safety-event",
      "intersection-safety-event",
    ];
    if (newStrategies.includes(strategy)) {
      const safetyEvents = data.data.safety_events || [];
      alert.data.safetyEvents = safetyEvents.map(
        (v: { op_name: string; timestamp: number; bbox_ranges: any }) => ({
          opName: v.op_name,
          timestamp: v.timestamp * 1000,
          bboxRanges: parseBBoxes(v.bbox_ranges),
        })
      );
    } else {
      // Get rid of it after https://git.srv.assaia.com/Apron-SU/apron-su/-/issues/10589
      const timeStamps = data.data.safety_event_timestamps || [];
      alert.data.safetyEvents = timeStamps.map((ts: number) => ({
        timestamp: ts * 1000,
        bboxRanges: {},
      }));
    }
  }
  return alert;
}

export const parseIncidentConfig = (data: any): IncidentConfig => {
  const config: IncidentConfig = camelCaseKeys(data);
  config.data = camelCaseKeys(config.data);
  config.data.type = data.data.strategy;
  config.data.incidentType = data.incident_type;

  if (data.detector_strategy === "weighted-safety-event") {
    // RE made hack, so we must fix incident_type here
    config.data.incidentType = "safety-event";
    config.incidentType = "safety-event";
  }

  return config;
};

export function parsePts(data: any): Pts | null {
  if (!data) {
    return null;
  }

  const newData = camelCaseKeys(data);
  newData.schedules = data.schedules.map((v: any) => camelCaseKeys(v));

  const toMs = (v: number | null) => (v !== null ? v * 1000 : null);

  newData.schedules = uniqWith(
    newData.schedules,
    (a: any, b: any) => a.id === b.id
  );
  newData.schedules = newData.schedules.map((s: any) => ({
    ...s,
    start: {
      referencePoint: s.start.referencePoint || "sobt",
      idealTime: s.start.idealTime * 1000,
      redInterval: {
        start: toMs(s.start.redInterval.start),
        end: toMs(s.start.redInterval.end),
      },
      orangeInterval: {
        start: toMs(s.start.orangeInterval.start),
        end: toMs(s.start.orangeInterval.end),
      },
    },
    end: {
      referencePoint: s.end.referencePoint || "sobt",
      idealTime: s.end.idealTime * 1000,
      redInterval: {
        start: toMs(s.end.redInterval.start),
        end: toMs(s.end.redInterval.end),
      },
      orangeInterval: {
        start: toMs(s.end.orangeInterval.start),
        end: toMs(s.end.orangeInterval.end),
      },
    },
  }));

  return newData;
}

export function parseTimestampsDict(
  data: Record<string, number | null | undefined>
) {
  const result: Record<string, number> = {};
  Object.entries(data).forEach(([key, val]) => {
    val = toMilliSecondsOrNull(val || null);
    if (val) {
      result[key] = val;
    }
  });
  return result;
}

export const parseTimestamps = (
  data: any
): Pick<
  TimelineResponse,
  "absoluteTime" | "lastImageTimestamp" | "inferenceTimestamp"
> => {
  const { last_prediction_ts, last_image_ts } = data;
  const { current_ts } = data;

  Object.entries(last_prediction_ts).forEach(([cam, ts]) => {
    if (!last_image_ts[cam]) {
      last_image_ts[cam] = ts;
    }
  });

  return {
    inferenceTimestamp: parseTimestampsDict(last_prediction_ts),
    lastImageTimestamp: parseTimestampsDict(last_image_ts),
    absoluteTime: current_ts * 1000,
  };
};
