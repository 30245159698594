import { Stand } from "@models/stand";
import { getConfig } from "@di";

export const getAliasedStandLabel = ({ alias, label }: Stand) => {
  const str = alias ? `${label}/${alias}` : label;

  return str.toUpperCase();
};

export const generateLabelById = (id: string) => {
  return id.split("-").slice(1).join("-");
};

export const getStandLabelByStandId = (standId: string): string | null => {
  const { standIdToStandLabelMap } = getConfig();
  return standIdToStandLabelMap[standId] || null;
};
